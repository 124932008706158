import {controller, target} from '@github/catalyst'

@controller
export class EmojiPickerElement extends HTMLElement {
  @target emojiList!: HTMLElement
  @target filterTabButton!: HTMLElement
  @target noResults!: HTMLElement
  @target resultsHeader!: HTMLElement
  @target filterInput!: HTMLInputElement | null

  open() {
    this.hidden = false
    this.filterInput?.focus()

    document.addEventListener('keydown', navigateEmojiInPicker)
    document.addEventListener('click', handleNonEmojiPickerClick)
  }

  close() {
    this.hidden = true

    document.removeEventListener('keydown', navigateEmojiInPicker)
    document.removeEventListener('click', handleNonEmojiPickerClick)
  }

  disonnectedCallback() {
    document.removeEventListener('keydown', navigateEmojiInPicker)
  }

  reset() {
    const originalEmojiTabButton = this.querySelector('.js-original-emoji-category')
    const originalEmojiTab = this.querySelector<HTMLElement>('.js-original-emoji-category-tab')!
    const selectedEmojiTabButton = this.querySelector('[role="tablist"] [role="tab"][aria-selected="true"]')
    const selectedEmojiTab = getActiveEmojiTab(this)

    const originallySelectedEmoji = this.querySelector('.js-originally-selected-emoji')
    if (originallySelectedEmoji) {
      const tempSelectedEmoji = this.querySelector('.selected-emoji')
      if (tempSelectedEmoji) {
        tempSelectedEmoji.classList.remove('selected-emoji')
      }

      originallySelectedEmoji.classList.add('selected-emoji')
    }

    selectedEmojiTabButton?.removeAttribute('aria-selected')
    originalEmojiTabButton?.setAttribute('aria-selected', 'true')

    selectedEmojiTab.hidden = true
    originalEmojiTab.hidden = false

    this.clear()
  }

  clear() {
    if (this.filterInput) {
      this.filterInput.value = ''
    }

    this.emojiList.setAttribute('value', '')
    this.filterTabButton.hidden = true
  }

  filter(event: Event) {
    const input = event.currentTarget as HTMLInputElement

    this.emojiList.setAttribute('value', input.value)

    const selectedEmojiTabButton = this.querySelector('[role="tablist"] [role="tab"][aria-selected="true"]')!
    if (!selectedEmojiTabButton.hasAttribute('data-emoji-picker-filter-tab-button')) {
      selectedEmojiTabButton.classList.add('emoji-picker-prior-selected-tab-button')
    }

    if (input.value) {
      this.filterTabButton.hidden = false

      const isFilterTabSelected = this.filterTabButton.getAttribute('aria-selected') === 'true'
      if (!isFilterTabSelected) {
        this.filterTabButton.click()
        input.focus()
      }
    } else {
      this.filterTabButton.hidden = true

      const priorSelectedTabButton = this.querySelector<HTMLButtonElement>('.emoji-picker-prior-selected-tab-button')
      if (priorSelectedTabButton) {
        priorSelectedTabButton.click()
      }
    }
  }

  onFuzzyListSorted(event: CustomEvent) {
    const hasResults = Number(event.detail) > 0
    this.noResults.hidden = hasResults
    this.emojiList.hidden = !hasResults
    this.resultsHeader.hidden = !hasResults
  }
}

function getActiveEmojiTab(picker: Element): HTMLElement {
  const tabButtons = picker.querySelectorAll('[role="tablist"] [role="tab"]')
  const activeTabButton = picker.querySelector<HTMLElement>('[role="tablist"] [role="tab"][aria-selected="true"]')!
  const tabPanels = picker.querySelectorAll<HTMLElement>('[role="tabpanel"]')
  const index = Array.from(tabButtons).indexOf(activeTabButton)

  return tabPanels[index]
}

// Use positional values to infer the current column count of the emoji picker.
// This allows the grid to be resized through css and have this code still work
// correctly.
function getEmojiColCount(allEmoji: Element[]): number {
  let rect: ClientRect | DOMRect | void
  let count = 0
  const firstEmoji = allEmoji[0]
  const firstRowTop = firstEmoji.getBoundingClientRect().top

  for (const emoji of allEmoji) {
    rect = emoji.getBoundingClientRect()

    if (firstRowTop < rect.top) {
      break
    }

    count += 1
  }

  return count
}

type EmojiGrid = Element[][]

// Create a multi-dimensional array from a single array of emoji elements.
function createEmojiGrid(elementArr: Element[], numCols: number): EmojiGrid {
  const results: EmojiGrid = [[]]
  let cursor = 0

  for (let i = 0; i < elementArr.length; i++) {
    const element = elementArr[i]
    if (i > 0 && i % numCols === 0) {
      cursor += 1
      results.push([])
    }

    results[cursor].push(element)
  }

  return results
}

type GridPosition = {
  row: number
  col: number
}

function getEmojiGridPosition(grid: EmojiGrid, element: Element): GridPosition {
  let rowPos = 0
  let colPos = 0
  let row: Element[] = []
  let index = -1

  for (let i = 0; i < grid.length; i++) {
    row = grid[i]
    index = row.indexOf(element)

    if (index > -1) {
      colPos = index
      break
    }

    rowPos += 1
  }

  return {row: rowPos, col: colPos}
}

function selectEmoji(picker: HTMLElement, key: 'ArrowRight' | 'ArrowLeft' | 'ArrowDown' | 'ArrowUp') {
  const selectedEmojiTab = getActiveEmojiTab(picker)
  const allEmoji = Array.from(selectedEmojiTab.querySelectorAll('.js-emoji-button'))
  const currentEmoji = selectedEmojiTab.querySelector('.selected-emoji.js-emoji-button')
  const colCount = getEmojiColCount(allEmoji)
  const grid = createEmojiGrid(allEmoji, colCount)
  let row = 0
  let col = 0

  if (currentEmoji) {
    const currentPos = getEmojiGridPosition(grid, currentEmoji)
    row = currentPos.row
    col = currentPos.col

    currentEmoji.classList.remove('selected-emoji')

    switch (key) {
      case 'ArrowRight':
        col += 1
        break
      case 'ArrowDown':
        row += 1
        break
      case 'ArrowLeft':
        col -= 1
        break
      case 'ArrowUp':
        row -= 1
        break
    }
  }
  const lastGridItem = grid.length - 1
  const columnOnLastLineDoesntExist = col > grid[lastGridItem].length - 1
  const rowIsLastRow = row === lastGridItem
  const isDownArrow = key === 'ArrowDown'

  if (row < 0) {
    row = lastGridItem

    // Skip the bottom row when bottom row is only partially full of emoji and
    // the cursor lands on an empty spot.
    if (columnOnLastLineDoesntExist) {
      row -= 1
    }
  } else if (row > lastGridItem || (rowIsLastRow && columnOnLastLineDoesntExist && isDownArrow)) {
    row = 0
  }

  const currentColLength = grid[row].length - 1

  if (col < 0) {
    col = currentColLength
  } else if (col > currentColLength) {
    col = 0
  }

  const selectedEmoji = grid[row][col] as HTMLElement
  selectedEmoji.classList.add('selected-emoji')
  selectedEmoji.focus()
}

function navigateEmojiInPicker(event: KeyboardEvent) {
  if (!(event.target instanceof HTMLElement)) {
    return
  }

  const picker = event.target.closest('.js-emoji-picker')
  if (!picker || !(picker instanceof HTMLElement) || picker.hidden) {
    return
  }

  switch (event.key) {
    case 'ArrowRight':
    case 'ArrowLeft':
    case 'ArrowUp':
    case 'ArrowDown':
      event.preventDefault()
      selectEmoji(picker, event.key)
      break
  }
}

function handleNonEmojiPickerClick(event: Event) {
  let el = event.target as Node
  if (!('closest' in el)) {
    el = el.parentNode!
  }

  const emojiPicker = (el as Element).closest<EmojiPickerElement>('emoji-picker')

  for (const picker of document.querySelectorAll<EmojiPickerElement>('emoji-picker')) {
    if (picker !== emojiPicker) {
      picker.close()
    }
  }
}
